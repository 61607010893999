<template>
  <main v-if="entity">
    <div class="product-pic">
      <img :src="entity.pic.indexOf('https://') != -1?entity.pic:'http://www.bncc.com/'+entity.pic" alt="" />
    </div>
    <div class="product-info">
      <div class="product-name">{{ entity.title }}</div>
      <div v-if="entity.type != 16" class="operation">{{ entity.needCredit }} 积分</div>
    </div>
    <div class="product-detail">
      <div class="title">商品详情</div>
      <div class="content">
        <table border="1" cellspacing="0" cellpadding="10px">
          <thead>
            <tr>
              <th colspan="2">基本信息</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="name">产品名称</td>
              <td>{{ entity.title }}</td>
            </tr>
            <tr>
              <td class="name">产品介绍</td>
              <td>{{ entity.introduce }}</td>
            </tr>

            <tr>
              <td class="name">市场价</td>
              <td>{{ entity.marketPrice }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="footer">
      <div class="button-wrapper" v-if="entity.type!=16">
        <ui-button type="submit" @handleclick="onChange"> 兑换</ui-button>
      </div>
      <div class="button-wrapper" v-else>
        <ui-button type="submit" @click="onChange2"> 兑换</ui-button>
      </div>
    </div>
  </main>
  <loading :options="loadOption" v-else></loading>


  <!--        弹窗-->
  <div class="modal1" id="modal1" v-if="showModal">
    <div class="modal-backdrop"></div>
    <div class="modal-body">
      <div class="modal-body-title">请输入您的兑换码</div>
      <div class="like-user-search" >
        <input id="extractCode" type="text" placeholder="请输入您的兑换码" v-model="extractCode">
      </div>
      <div class="coupon-button">
        <span class="coupon_btn" @click="onChangeExtract()">兑换</span>
        <span class="coupon_btn" style="background: #999" @click="closeModal()">取消</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import Toast from "@/components/UI/Toast";
import axios from "@/api/axios";
import { useRoute, useRouter } from "vue-router";
import Loading from "@/components/UI/Loading";
import UiButton from "@/components/UI/Button";
import qs from "qs";
import Modal from "@/components/UI/Modal";

export default defineComponent({
  name: "Exchange",
  components: {
    Loading,
    UiButton,
  },
  setup() {
    const route = useRoute();
    const id = route.query.id;
    const entity = ref(null);
    const showModal =ref(false);
    const extractCode=ref("");
    axios
      .get(`/M/User/GiftDetail/${id}`, {
        params: {
          validate: true,
        },
      })
      .then((res) => {
        if (res.data.success) {
          entity.value = res.data.obj.entity;
        } else {
          Toast({
            type: "error",
            title: res.data.msg,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    const loadOption = {
      text: "加载中...",
      color: "#df0024",
      textColor: "#df0024",
    };
    const router = useRouter();
    let pending = false;
    function onChange() {
      Modal({
        title: "温馨提示",
        content: "确认兑换该商品？",
        onConfirm: () => {
          if (!pending) {
            pending = true;
            const data = qs.stringify({
              id,
            });
            axios
              .post(`/M/Server/DoExchangeProduct`, data, {
                params: {
                  validate: true,
                },
              })
              .then((res) => {
                if (res.data.success) {
                  Toast({
                    type: "success",
                    title: res.data.msg,
                    duration: 2000,
                    onClose:()=>{
                       router.back();
                    }
                  });
                } else {
                  Toast({
                    type: "error",
                    title: res.data.msg,
                  });
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
          pending = false;
        },
        onClose: () => {
          pending = false;
        },
      });
    }
    function closeModal(){
      showModal.value = false;
    }
    function onChange2() {
      showModal.value = true;
    }
    function onChangeExtract() {
      if(!extractCode.value||extractCode.value==""){
        Toast({
          type: "warning",
          title: "请输入您的兑换码",
        });
        return false;
      }
      // console.log("extractCode",extractCode.value)
      const data = qs.stringify({
        id,
      });
      axios
              .post(`/M/Server/DoExtractCode?extractCode=`+extractCode.value,data, {
                params: {
                  validate: true,
                },
              })
            .then((res) => {
              if (res.data.success) {
                Toast({
                  title: res.data.msg,
                  type: "success",
                  duration: 2000,
                  // onChange(),
                  onClose: () => {
                    router.back();
                  },
                });

              } else {
                Toast({
                  title: res.data.msg,
                  type: "warning",
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });
    }
    return {
      entity,
      loadOption,
      onChange,
      onChange2,
      showModal,
      closeModal,
      onChangeExtract,
      extractCode
    };
  },
});
</script>

<style lang="scss" scoped>
main {
  padding-bottom: 69px;
}

.product-pic img {
  width: 375px;
  height: 375px;
  display: block;
}

.product-info {
  position: relative;
  padding: 10px;
  padding-top: 21.5px;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 6px solid #f4f4f4;
}

.product-name {
  width: 255px;
  color: #444444;
  font-size: 16px;
  position: relative;
  font-weight: bold;
}

.operation {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #cc3333;
  font-size: 15px;
  font-weight: 700;
}
.operation a {
  color: #cc3333;
  font-size: 15px;
  font-weight: 700;
}

.product-detail .title {
  padding-left: 20px;
  font-size: 15px;
  color: #444;
  position: relative;
  width: 375px;
  box-sizing: border-box;
  height: 44px;
  line-height: 44px;
  border-bottom: 1px solid #f4f4f4;
}
.product-detail .title::after {
  content: "";
  position: absolute;
  width: 2px;
  left: 10px;
  top: 50%;
  height: 13px;
  background-color: #df0024;
  margin-top: -6.5px;
}

table {
  width: 355px;
  border: none;
  margin: 0 auto;
  margin-top: 20px;
  border-collapse: collapse;
  border-color: #ccc;
}
thead {
  width: 355px;
  background-color: #d64b4b;
  border: 1px solid #d64b4b;
}
th {
  color: #fff;
  text-align: left;
  height: 30px;
  box-sizing: border-box;
  padding: 0 9px;
  line-height: 30px;
  font-size: 14px;
}
td {
  font-size: 14px;
  color: #666666;
  padding: 10px;
  word-break: break-word;
}

.name {
  width: 117px;
  box-sizing: border-box;
  border-right-color: #e5e5e5;
  background-color: #f2f2f2;
  line-height: 25px;
}

.other-view {
  margin-top: 20px;
  border-top: 1px solid #eee;
}
.other-view .title {
  text-align: justify;
  font-size: 16px;
  color: #666666;
  line-height: 25px;
  margin: 20px 10px;
  font-weight: bold;
}

.other-view .content a {
  text-align: justify;
  font-size: 16px;
  line-height: 25px;
  display: block;
  color: #666666 !important;
  margin: 12.5px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0%;
  right: 0;
  width: 100%;
  border-top: 1px solid #f4f4f4;

  background-color: #fff;
  z-index: 100;
  .button-wrapper {
    width: 345px;
    margin: 0 auto;
  }
}
.modal-backdrop{
  position: fixed;
  z-index: 99;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-body {
  background: white;
  //border-radius: 0.21333rem;
  z-index: 99;
  width: 7.53333rem;
  height: 130px;
  position: fixed;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 0.53333rem 0.13333rem;
  font-size: 0.37333rem;
}
.modal-body-title {
  text-align: center;
  position: relative;
  bottom: 0.26667rem;
}
.like-user-search{
  width:90%;
  margin:0 auto;
  padding: 0.13333rem;
}
.like-user-search input{
  width: 100%;
  height: 0.8rem;
  background-color: #ffffff;
  border: solid 0.02667rem #dddddd;
  padding: 0 0.26667rem;
  //border-radius: 0.37333rem;
  position: relative;
}
.coupon-button{
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  margin: 0 auto;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  margin-top: 10px;
}
.coupon_btn{
  color: #fff;
  font-size: 0.37333rem;
  margin-top: 0.18667rem;
  background: #d64b4b;
  padding: 0.10667rem 0.4rem;
  //border-radius: 0.16rem;
}
</style>
