
import { defineComponent, ref } from "vue";
import Toast from "@/components/UI/Toast";
import axios from "@/api/axios";
import { useRoute, useRouter } from "vue-router";
import Loading from "@/components/UI/Loading";
import UiButton from "@/components/UI/Button";
import qs from "qs";
import Modal from "@/components/UI/Modal";

export default defineComponent({
  name: "Exchange",
  components: {
    Loading,
    UiButton,
  },
  setup() {
    const route = useRoute();
    const id = route.query.id;
    const entity = ref(null);
    const showModal =ref(false);
    const extractCode=ref("");
    axios
      .get(`/M/User/GiftDetail/${id}`, {
        params: {
          validate: true,
        },
      })
      .then((res) => {
        if (res.data.success) {
          entity.value = res.data.obj.entity;
        } else {
          Toast({
            type: "error",
            title: res.data.msg,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    const loadOption = {
      text: "加载中...",
      color: "#df0024",
      textColor: "#df0024",
    };
    const router = useRouter();
    let pending = false;
    function onChange() {
      Modal({
        title: "温馨提示",
        content: "确认兑换该商品？",
        onConfirm: () => {
          if (!pending) {
            pending = true;
            const data = qs.stringify({
              id,
            });
            axios
              .post(`/M/Server/DoExchangeProduct`, data, {
                params: {
                  validate: true,
                },
              })
              .then((res) => {
                if (res.data.success) {
                  Toast({
                    type: "success",
                    title: res.data.msg,
                    duration: 2000,
                    onClose:()=>{
                       router.back();
                    }
                  });
                } else {
                  Toast({
                    type: "error",
                    title: res.data.msg,
                  });
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
          pending = false;
        },
        onClose: () => {
          pending = false;
        },
      });
    }
    function closeModal(){
      showModal.value = false;
    }
    function onChange2() {
      showModal.value = true;
    }
    function onChangeExtract() {
      if(!extractCode.value||extractCode.value==""){
        Toast({
          type: "warning",
          title: "请输入您的兑换码",
        });
        return false;
      }
      // console.log("extractCode",extractCode.value)
      const data = qs.stringify({
        id,
      });
      axios
              .post(`/M/Server/DoExtractCode?extractCode=`+extractCode.value,data, {
                params: {
                  validate: true,
                },
              })
            .then((res) => {
              if (res.data.success) {
                Toast({
                  title: res.data.msg,
                  type: "success",
                  duration: 2000,
                  // onChange(),
                  onClose: () => {
                    router.back();
                  },
                });

              } else {
                Toast({
                  title: res.data.msg,
                  type: "warning",
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });
    }
    return {
      entity,
      loadOption,
      onChange,
      onChange2,
      showModal,
      closeModal,
      onChangeExtract,
      extractCode
    };
  },
});
